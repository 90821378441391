<template>
  <li
    v-if="$CanViewMenu(item)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
    }"
  >
  <b-link v-if="item.icon!='UserIcon'" v-bind="linkProps" class="d-flex align-items-center ">
      <feather-icon :icon="item.icon || 'CircleIcon'" style="color:white"  />
      <span class="menu-title text-truncate" style="color:white" >{{ t(item.title) }} </span>
      <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto"> {{ item.tag }} </b-badge>
    </b-link>
    <b-link v-else v-bind="linkProps" class="d-flex align-items-center mt-50 ">
      <img width="25" height="25" style="margin-right:15px; border-radius: 50px; border:1px solid lightgray;" :src="profileURL + userData.profile" />

      <span class="menu-title text-truncate" style="color:white" >{{ t(item.title) }} </span>
      <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto"> {{ item.tag }} </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl';
import { BLink, BBadge } from 'bootstrap-vue';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import useVerticalNavMenuLink from './useVerticalNavMenuLink';
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink';
import store from '@/store';

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tempImage: null,
      profileURL: store.state.app.profileURL,
      profileFile: null,
      userData: JSON.parse(localStorage.getItem("userData")),
    };
  },

  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item);
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    };
  },
};
</script>
